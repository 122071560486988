import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography';
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"

export default class IndexPage extends React.Component {

    render() {

        return (
           <Layout>
               <SEO title="Twitch Countdown Overlay Configurator"/>

               <Typography component="h1">
                   Twitch Countdown Configurator
               </Typography>
               <br/>
               <Typography variant="body2" component="p">
                   Hello & Welcome to our twitch countdown overlay configurator!
                   <br/><br/>
                   We created a small tool which allows streamers to create and add a timer overlay to their streams. See below for the available
                   options.
                   <br/><br/>
                   In the sidebar on the left your will find the links to the tools as well as the help page. Here you can find detailed
                   steps on how you can add the timers to your stream.
                   <br/><br/>
                   Thee following Options are available:
                   <br/>
                   <br/>
               </Typography>

              <Grid container style={{ textAlign: "center" }}>
                   <Grid item sm={6}>
                       <div style={{ paddingRight: "10px"}}>
                       <Card raised>
                           <CardHeader
                              title="Countdown from hours, minutes and seconds"
                           />
                           <CardContent>
                               <Typography variant="body2" component="p">
                                   Creates an overlay which counts from specific defined hours, minutes and seconds downwards and stops by 0.
                               </Typography>
                           </CardContent>
                           <CardActions>
                               <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: "100%"}}
                                  href="countdown-from"
                                >
                                   Create Overlay
                               </Button>
                           </CardActions>
                       </Card>
                       </div>
                   </Grid>
                   <Grid item sm={6}>
                       <div style={{ paddingLeft: "10px"}}>
                       <Card raised>
                           <CardHeader
                              title="Countdown to a specific Date and Time"
                           />
                           <CardContent>
                               <Typography variant="body2" component="p">
                                   Creates an overlay which counts down to a specific date and time and stops by 0.
                                   <br/>
                                   <br/>
                               </Typography>
                           </CardContent>
                           <CardActions>
                               <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: "100%"}}
                                  href="countdown-to"
                               >
                                   Create Overlay
                               </Button>
                           </CardActions>
                       </Card>
                       </div>
                   </Grid>
               </Grid>
           </Layout>
        )
    }
}